@font-face {
    font-family: 'museo';
    src: url('fonts/museo300-regular-webfont.eot');
    src: url('fonts/museo300-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/museo300-regular-webfont.woff2') format('woff2'),
         url('fonts/museo300-regular-webfont.woff') format('woff'),
         url('fonts/museo300-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'qanelas';
    src: url('fonts/radomir_tinkov_-_qanelassoft-black-webfont.eot');
    src: url('fonts/radomir_tinkov_-_qanelassoft-black-webfont.eot?#iefi') format('embedded-opentype'),
         url('fonts/radomir_tinkov_-_qanelassoft-black-webfont.woff2') format('woff2'),
         url('fonts/radomir_tinkov_-_qanelassoft-black-webfont.woff') format('woff'),
         url('fonts/radomir_tinkov_-_qanelassoft-black-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/** Body Text **/
body {
    font-family: 'museo', Arial, sans-serif;
}
h1, h2, h3, h4 {
    font-family: 'qanelas', Gadget, sans-serif;
    font-weight: bold;
}
h1,h2 {
    text-transform: uppercase;
}

h2 {
    font-size: 50px;
    line-height: 50px;
    margin:0;
}

.pink {
    color: #efa0af;
}
.orange {
    color: #f1892c;
}


/****** div styles *******/
.half {
    display: inline-block;
    vertical-align: top;
    width: 48%;
    box-sizing: border-box;
}

.staff {
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    width: 150px;
    max-width: 100%;
    font-size: 20px;
    font-family: 'qanelas', Gadget, sans-serif;
    font-weight: bold;
    margin: 2%;
    margin-top: 20px;
    -webkit-transition: color 0.5s ease-in-out;
    transition: color 0.5s ease-in-out;
}
.staff a:hover img {
    border: 3px solid #fdd82b;
}

.staff img {
    display: inline-block;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    -webkit-transition: border 0.5s ease-in-out;
    transition: border 0.5s ease-in-out;
}
.staff p,
.staff h4 {
    margin:0;
    -webkit-transition: color 0.5s ease-in-out;
    transition: color 0.5s ease-in-out;
}
.staff p,
.staff a p {
    font-family: 'museo', Arial, sans-serif;
    font-size: 12px;
    color: #000;
}

/*** location content styles ***/
.times_icon,
.days_icon,
.people_icon {
    position: relative;
    display: inline-block;
    width: 30%;
    min-width: 180px;
    vertical-align: top;
    text-align:left;
    padding-left: 40px;
    text-transform: uppercase;
    font-weight: bold;
    color: #8dbb35;
    box-sizing: border-box;
}

.times_icon {
    line-height: 30px;
}

.times_icon:before {
    position: absolute;
    content:"";
    top: 0;
    left:0;
    width: 30px;
    height: 30px;
    background: url('../assets/img/clock.png') no-repeat;
    background-size: contain;
}
.days_icon:before {
    position: absolute;
    content:"";
    top: 0;
    left:0;
    width: 30px;
    height: 30px;
    background: url('../assets/img/calendar.svg') no-repeat;
    background-size: contain;
}
.people_icon:before {
    position: absolute;
    content:"";
    top: 0;
    left:0;
    width: 30px;
    height: 30px;
    background: url('../assets/img/person.png') no-repeat;
    background-size: contain;
}

/**** butterfly classes ****/
iframe {
    display: inline-block;
    vertical-align: top;
    min-width: 48%;
    max-width: 100%;
}
.red_butterfly,
.purple_butterfly,
.pink_butterfly,
.orange_butterfly,
.lime_butterfly,
.yellow_butterfly,
.emerald_butterfly {
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-align: left;
    width: 48%;
    padding-right: 11%;
    padding-left: 30px;
    box-sizing: border-box;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
}
.red_butterfly:before {
    position: absolute;
    content:"";
    top: 0;
    left:0;
    width: 20px;
    height: 20px;
    background: url('../assets/img/red-butterfly.png') no-repeat;
    background-size: contain;
}
.purple_butterfly:before {
    position: absolute;
    content:"";
    top: 0;
    left:0;
    width: 20px;
    height: 20px;
    background: url('../assets/img/purple-butterfly.png') no-repeat;
    background-size: contain;
}
.pink_butterfly:before {
    position: absolute;
    content:"";
    top: 0;
    left:0;
    width: 20px;
    height: 20px;
    background: url('../assets/img/pink-butterfly.png') no-repeat;
    background-size: contain;
}
.orange_butterfly:before {
    position: absolute;
    content:"";
    top: 0;
    left:0;
    width: 20px;
    height: 20px;
    background: url('../assets/img/orange-butterfly.png') no-repeat;
    background-size: contain;
}
.lime_butterfly:before {
    position: absolute;
    content:"";
    top: 0;
    left:0;
    width: 20px;
    height: 20px;
    background: url('../assets/img/lime-butterfly.png') no-repeat;
    background-size: contain;
}
.yellow_butterfly:before {
    position: absolute;
    content:"";
    top: 0;
    left:0;
    width: 20px;
    height: 20px;
    background: url('../assets/img/yellow-butterfly.png') no-repeat;
    background-size: contain;
}
.emerald_butterfly:before {
    position: absolute;
    content:"";
    top: 0;
    left:0;
    width: 20px;
    height: 20px;
    background: url('../assets/img/emerald-butterfly.png') no-repeat;
    background-size: contain;
}

/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (max-width:1100px){
    .half {
        width: 100%;
    }
}
@media only screen and (max-width:500px){
    .red_butterfly,
    .purple_butterfly,
    .pink_butterfly,
    .orange_butterfly,
    .lime_butterfly,
    .yellow_butterfly,
    .emerald_butterfly {
        width: 100%;
        padding-right:0;
    }
}
@media only screen and (max-width:460px){
    /* h2's smaller */
    h2 {
        font-size: 1.5em;
        line-height: 1.5em;
    }
}
@media only screen and (max-width: 400px) {
    .staff {
        width: 100%;
    }
}

